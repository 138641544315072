/* General styling for the form */
.form-container {
    margin: auto;
    max-width: 800px; /* Increased max-width for better usability */
    padding: 20px;
    width: 100%; /* Ensure the container uses full width */
}

/* Styling for form fields */
.form-field {
    width: 100%; /* Ensure form fields occupy the full width */
}

/* Styling for the countdown text */
.countdown-text {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #3f51b5;
}

/* Styling for the buttons */
.button-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Snackbar styling */
.snackbar-alert {
    width: 100%;
}

/* Styling for the PDF download link */
.pdf-download-link {
    text-decoration: none;
}

/* Styling for the download button */
.download-button {
    margin-top: 10px;
}
.report-issue-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.report-issue-title {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
}

.report-issue-form {
    width: 100%;
    max-width: 500px;
}

.report-issue-form form {
    display: flex;
    flex-direction: column;
}

.report-issue-form label {
    margin-bottom: 10px;
    font-weight: bold;
}

.report-issue-form textarea {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.submit-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0077B5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
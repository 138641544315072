/* support.css */

.support-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    margin: auto;
    margin-top: 50px;
}

.support-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.support-form {
    width: 100%;
}

.support-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.support-form label {
    color: #333;
    font-size: 16px;
    font-weight: 500;
}

.support-form input[type="text"],
.support-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.support-form .submit-button {
    background-color: #0077B5;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.support-form .submit-button:hover {
    background-color: #0077B5;
}
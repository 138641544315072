/* GetStarted.css */
.get-started-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
}

.progress-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    flex-direction: row;
}

.step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    /* Ensure the steps distribute space evenly */
    position: relative;
    /* For pseudo-element positioning */
}

.step {
    width: 30px;
    height: 30px;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: bold;
    margin-bottom: 4px;
    /* Space for the title */
    z-index: 1;
    /* Ensure the step is above the line */
}

.active-step {
    background-color: #007bff;
    color: white;
}

.step-title {
    font-size: 14px;
    color: #666666;
    /* Color for the title text */
}

.progress-bar-line {
    height: 4px;
    background-color: #d9d9d9;
    position: absolute;
    left: 15px;
    /* Half of step width to center the line */
    right: 15px;
    /* Half of step width to center the line */
    top: 15px;
    /* Half of step height to align the line vertically */
}

.upload-section {
    text-align: center;
    border: 1px dashed #d9d9d9;
    padding: 20px;
    border-radius: 8px;
}

.upload-section h2 {
    margin-bottom: 10px;
}

.upload-section button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.upload-section button:hover {
    background-color: #0056b3;
}

.upload-section .file-types {
    margin: 10px 0;
}

input[type="file"] {
    margin: 10px 0;
}

.dropzone {
    border: 2px dashed #d9d9d9;
    padding: 20px;
    border-radius: 8px;
    margin: 10px 0;
    cursor: pointer;
    background-color: #f8f8f8;
}

.dropzone p {
    margin: 0;
}

.dropzone:hover {
    border-color: #007bff;
}
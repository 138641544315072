.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f6f8fa;
    border-radius: 6px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background-color: hsl(0, 0%, 100%);
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin: auto;
}

.resume-announcement-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.month-label {
    font-size: 0.75rem;
    color: #586069;
    text-align: center;
    flex: 0 0 14px;
    height: 14px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.week-container {
    display: flex;
    flex-direction: column;
}

.day-square {
    width: 14px;
    height: 14px;
    margin: 2px;
    flex: 0 0 auto;
}

.button,
.day-square {
    min-width: 44px;
    /* Google's recommended touch target size */
    min-height: 44px;
    margin: 4px;
}


/* .month-label, .day-square {
    font-size: 0.65rem;
    width: 12px;
    height: 12px;
    margin: 1px;
    flex: 0 0 auto;
} */

.dashboard-container {
    padding: 10px;
}

@keyframes shimmer {
    0% {
        background-position: -40rem 0;
    }

    100% {
        background-position: 40rem 0;
    }
}

.Skeleton {
    width: 100%;
}

body {
    font-size: 1em;
    /* Base font size */
}

.shimmer {
    animation: shimmer 1s infinite linear;
    background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #d7d7d7 33%);
    background-size: 40rem 100%;
}

@media (max-width: 600px) {
    .dashboard-container {
        padding: 5px;
    }

    body {
        font-size: 0.9em;
        /* Slightly smaller font size on small devices */
    }
}

.tailored-resume-container {
    margin-top: 16px;
}
.resumes-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 12px;
}

.resume-card {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.2s;
}

.resume-card:hover {
    transform: translateY(-5px);
}

.resume-card h3 {
    margin-top: 0;
    color: #333;
    font-size: 16px;
}

.resume-card .resume-details .generated-at {
    color: #444;
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.resume-card .resume-link {
    display: inline-block;
    background-color: #0077B5;
    color: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    text-decoration: none;
    font-size: 14px;
    margin-top: 2px;
}

.resume-card .resume-link:hover {
    background-color: #0056b3;
}

.resume-skeleton {
    border-radius: 8px;
    margin-bottom: 2px;
}

.end-message {
    text-align: center;
    padding: 2px;
    font-size: 14px;
    color: #666;
}

.resume-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.resume-title {
    margin-right: 10px;
}

.resume-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
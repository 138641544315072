.career-hacks-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career-hacks-title {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
}

.hacks-list {
    width: 100%;
    max-width: 600px;
}

.hack-item {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hack-item h3 {
    margin-bottom: 10px;
    color: #0077B5;
}

.hack-item p {
    font-size: 16px;
    line-height: 1.5;
}
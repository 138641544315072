.jobs-applied {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    max-width: 1000px;
    margin: auto;
}

.table-container {
    overflow-x: auto;
    width: 100%;
}

.jobs-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    table-layout: fixed;
}

.jobs-table th,
.jobs-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
}

.jobs-table td {
    max-width: 200px;
}

.jobs-table th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 1;
}

.jobs-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.jobs-table tbody tr:hover {
    background-color: #f1f1f1;
}

.jobs-table th,
.jobs-table td a {
    color: #333;
    text-decoration: none;
}

.jobs-table td a {
    word-break: break-all;
}

.jobs-table td a:hover {
    text-decoration: underline;
}

.end-message {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #4a4a4a;
    padding: 20px;
    text-align: center;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.coming-soon {
    text-align: center;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 15px;
    margin: auto;
    box-sizing: border-box;
}

.coming-soon h2 {
    font-size: 24px;
    margin-top: 20px;
    color: #333;
}

.coming-soon p {
    font-size: 16px;
    color: #666;
}

@media (max-width: 768px) {
    .coming-soon h2,
    .coming-soon p {
        padding: 0 10px;
    }
}

@media (max-width: 480px) {
    .coming-soon h2 {
        font-size: 20px;
    }

    .coming-soon p {
        font-size: 14px;
    }
}

.no-jobs {
    text-align: center;
    margin-top: 50px;
}


/* .jobs-applied {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    color: #333;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #007bff;
}

.table-container {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
}

.jobs-table {
    width: 100%;
    max-width: 1000px;
    border-collapse: collapse;
    background-color: #f9f9f9;
}

.jobs-table th, .jobs-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.jobs-table th {
    background-color: #007bff;
    color: white;
    text-transform: uppercase;
}

.jobs-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.jobs-table th.job-title, .jobs-table td.job-title {
    width: 30%;
}

.jobs-table th.company, .jobs-table td.company {
    width: 20%;
}

.jobs-table th.applied-date, .jobs-table td.applied-date {
    width: 20%;
}

.jobs-table th.job-link, .jobs-table td.job-link {
    width: 30%;
}

.job-link {
    display: flex;
    align-items: center;
    color: #007bff;
    text-decoration: none;
}

.job-link:hover {
    text-decoration: underline;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.end-message {
    text-align: center;
    margin-top: 20px;
    color: #777;
}

.no-jobs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    width: 100%;
} */
.job-flow-component {
    background-color: #f8f9fa;
    padding: 40px 60px;
    text-align: center;
    width: 100%;
}

.job-flow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 960px;
    margin: 0 auto;
}

.job-flow-step {
    width: 100%;
}

.job-flow-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px 25px;
    text-align: center;
    min-width: 150px;
    max-width: 300px;
    margin: 0 auto;
}

.job-flow-icon, .job-flow-arrow {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 5px;
}

.job-flow-title {
    font-size: 24px;
    color: #2c3e50;
    margin-bottom: 8px;
}

.job-flow-subtitle {
    font-size: 18px;
    color: #7f8c8d;
    margin-bottom: 20px;
}

.job-flow-arrow {
    font-size: 2rem;
    color: #007bff;
    animation: bounce 2s infinite;
    margin-top: 14px;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .job-flow-component {
        padding: 20px 30px; /* Adjust padding for smaller screens */
    }

    .job-flow-title, .job-flow-subtitle {
        font-size: 16px; /* Adjust text size for readability */
    }

    .job-flow-icon, .job-flow-arrow {
        font-size: 1.5rem; /* Scale down icons for smaller screens */
    }

    .job-flow-arrow {
        margin-top: 10px; /* Adjust spacing above arrows */
    }

    .job-flow-card {
        padding: 10px 15px; /* Reduce padding inside cards for more space */
    }
}
/* JobPreferences.css */
.job-preferences {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-preferences h1 {
    text-align: center;
    color: #333;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: bold;
}

button {
    padding: 10px 15px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #003d82;
}

.job-preferences-input,
.job-preferences-select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
}

.phone-input-group {
    display: flex;
    align-items: center;
}

.country-code-input,
.phone-number-input {
    flex: 1;
}

.country-code-input {
    margin-right: 10px;
}

.country-code-input input {
    width: 60px; /* Set a fixed width for the country code input */
    margin-right: 5px; /* Add a small margin to push the mobile number input to the left */
}
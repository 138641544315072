.announcement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    padding: 0 10px;
}

.announcement-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    margin: 20px; /* Adds margin for smaller devices */
}

.announcement-title {
    color: #333333;
    margin-bottom: 10px;
    padding: 0 10px;
}

.announcement-message {
    color: #666666;
    margin-bottom: 20px;
    padding: 0 10px;
}

.announcement-button {
    background-color: #0077B5;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.announcement-button:hover {
    background-color: #0077B5;
}

@media (max-width: 768px) {
    .announcement-container {
        height: auto; /* Adjusts height for smaller screens */
        padding: 20px; /* Adds more padding on smaller devices */
    }

    .announcement-card {
        padding: 15px; /* Reduces padding inside the card for smaller screens */
    }

    .announcement-title, .announcement-message {
        padding: 0 10px; /* Adds padding to text for edge spacing */
    }
}

/* Additional media query for very small devices (phones) */
@media (max-width: 480px) {
    .announcement-title {
        font-size: 1.125rem; /* Slightly reduces font size for the title */
    }

    .announcement-message {
        font-size: 0.875rem; /* Reduces font size for messages */
    }

    .announcement-button {
        padding: 8px 16px; /* Adjusts button padding for smaller screens */
    }
}
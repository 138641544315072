.hero-section {
    width: 100%;
    overflow: hidden;
}

.feature-highlights {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
    z-index: 2;
}

.feature-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: calc(100% / 3);
    max-width: 400px;
    text-align: center;
    transition: transform 0.3s ease;
    z-index: 2;
    margin-top: 200px;

    @media (max-width: 1200px) {
        margin-top: 150px;
    }

    @media (max-width: 768px) {
        margin-top: 100px;
    }

    @media (max-width: 480px) {
        margin-top: 50px;
    }

    .feature-icon {
        margin-bottom: 1rem;
    }

    @media (max-width: 1200px) {
        .feature-card {
            width: calc(100% / 2 - 2rem);
        }
    }

    @media (max-width: 768px) {
        .feature-card {
            width: calc(100% - 2rem);
        }
    }
}

.feature-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.feature-description {
    font-size: 1rem;
}
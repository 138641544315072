.resume-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.resume-sections {
    margin-top: 20px;
}

.resume-section,
.resume-item-header {
    margin-bottom: 30px;
}

.resume-item {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.editable-textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-sizing: border-box;
    resize: vertical;
    min-height: 400px;
}

.editable-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.editable-input:focus {
    border-color: #4a90e2;
    outline: none;
}

.save-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4a90e2;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}

.save-button:hover {
    background-color: #3a7ac7;
}

.field-group {
    margin-bottom: 15px;
}

.field-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.dates-group,
.skills-list {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.dates-group>div,
.description-points,
.description-point {
    flex: 1;
    padding-right: 10px;
}

.description-point {
    margin-bottom: 5px;
    text-align: justify;
}

.error-field {
    border: 2px solid red;
}

.delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #0077B5;
    animation: spin 1s ease infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.save-button {
    cursor: pointer;
    padding: 10px 20px;
    background-color: #0077B5;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.save-button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
    color: #666;
}
/* linkedin_post.css */
.linkedin-post-mockup {
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    max-width: 600px;
    margin: 20px auto;
    padding: 16px;
    margin-bottom: 30px;
    overflow: hidden; /* Prevent overflow of child elements */
}

.linkedin-post-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.logo {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-right: 10px;
}

.post-header-details h2 {
    margin: 0;
    color: #0073b1;
    font-size: 16px;
    font-weight: bold;
}

.post-header-details p {
    margin: 0;
    color: #00000099;
    font-size: 12px;
}

.world-icon {
    display: inline;
}

.linkedin-post-body {
    margin-bottom: 12px;
}

.post-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 8px;
}

.linkedin-post-footer {
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
    max-width: 100%;
}

.like-comment-share {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.like-comment-share button {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    color: #0a66c2;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    margin-right: 4px;
    border-radius: 8px;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.post-footer-details p {
    font-size: 12px;
    color: #00000099;
    margin: 0;
}

/* Add responsive design as necessary */
@media (max-width: 768px) {
    .linkedin-post-mockup {
        max-width: 100%;
        padding: 12px;
    }

    .like-comment-share button {
        padding: 4px;
        font-size: 12px;
    }

    .comment-field {
        flex-grow: 0;
        max-width: 100%;
        width: auto;
    }

    .comment-field-container {
        flex-grow: 0;
        max-width: 100%;
        padding: 8px;
    }

    .linkedin-post-footer {
        flex-grow: 0;
        max-width: 100%;
    }

}


.like-comment-share button:hover {
    background-color: #e7f3ff;
    color: #0a66c2;
}

.like-comment-share button:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
    background-size: cover;
}

.like-comment-share button:not(:last-child) {
    margin-right: 8px;
}

.like-comment-share button:hover {
    background-color: #e7f3ff;
    color: #0a66c2;
}

.like-comment-share button:focus {
    outline: none;
}

.like-comment-share button.like .btn-content,
.like-comment-share button.comment .btn-content,
.like-comment-share button.repost .fa-retweet,
.like-comment-share button.send .btn-content {
    display: flex;
    align-items: center;
}

.like-comment-share button.like .btn-content:before,
.like-comment-share button.comment .btn-content:before,
.like-comment-share button.send .btn-content:before {
    margin-right: 8px;
}

.like-comment-share button.like .btn-content:before {
    content: "👍";
}

.like-comment-share button.comment .btn-content:before {
    content: "💬";
}

.like-comment-share button.send .btn-content:before {
    content: "✉️";
}

.like-comment-share button.repost .fa-retweet {
    margin-right: 8px;
}

.comment-field-container {
    display: inline-flex;
    align-items: center;
    padding: 8px;
    background-color: #f3f2ef;
    border-radius: 22px;
    margin-top: 8px;
    max-width: 100%;
    flex-wrap: wrap;
    width: calc(100% - 16px);
}

.profile-pic {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin-right: 8px;
}

.comment-field {
    flex-basis: auto;
    /* Take up the space based on content size or explicit width */
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
    /* Limit the width to the container's width */
    border: none;
    background-color: transparent;
    outline: none;
    padding: 4px 8px;
}

.comment-icons {
    display: flex;
    align-items: center;
}

.icon-btn {
    background: none;
    border: none;
    padding: 4px;
    font-size: 16px;
    cursor: pointer;
}

/* Setting the color for the icons */
.icon-btn .fa {
    color: #606060;
    /* gray color */
}

.icon-btn .fa:hover {
    color: inherit;
    /* This ensures the color doesn't change on hover */
}

.icon-btn .fa-bolt {
    color: #0a66c2;
}

.icon-btn:focus {
    outline: none;
}


.icon-btn .fa-smile,
.icon-btn .fa-image {
    color: #606060;
}

.icon-btn:hover {
    background: none;
    border: none;
}

.post-footer-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.likes-count,
.comments-reposts-count {
    display: flex;
    align-items: center;
}



.comment-icons .icon-btn .fa {
    color: #606060;
    /* dark gray color */
}

/* Removing any hover effects on the icons */
.comment-icons .icon-btn .fa:hover {
    color: #606060;
    /* remains the same color */
}

/* Removing any hover effects on the button itself */
.comment-icons .icon-btn:hover {
    background: none;
    /* No background change on hover */
    border: none;
    /* No border change on hover */
}

/* Ensure no outline appears on focus for buttons */
.comment-icons .icon-btn:focus {
    outline: none;
}

.feedback-message {
    color: #ff4500;
    /* A bright color to grab attention */
    font-style: italic;
    padding: 8px 0;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
}

/* Keyframes for the fadeIn animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-3px);
    }

    50% {
        transform: translateX(3px);
    }

    75% {
        transform: translateX(-3px);
    }

    100% {
        transform: translateX(3px);
    }
}

.prompt-animation {
    animation: slideIn 0.5s ease forwards;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    background: #fff1c1;
    border-radius: 10px;
    border: 1px solid #0088ff;
    margin-bottom: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 100;
}

.icon-btn.bolt-active {
    animation: shake 0.5s ease infinite;
}

/* Rest of your styles */


@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    10%,
    90% {
        opacity: 1;
    }
}

@keyframes shake {

    0%,
    100% {
        transform: rotate(-3deg);
    }

    50% {
        transform: rotate(3deg);
    }
}

.speech-bubble {
    position: absolute;
    bottom: 20px;
    /* Position above the bolt icon */
    right: 50px;
    /* Adjust as necessary */
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 1px solid #ccc;
    animation: fadeInOut 5s ease forwards;
    opacity: 0;
    /* Start invisible */

    /* Create the triangle arrow */
    &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        /* Adjust as necessary */
        left: 50%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: #f0f0f0;
        border-bottom: 0;
        margin-left: -10px;
        margin-bottom: -10px;
    }
}

.bolt-active {
    animation: shake 0.5s ease infinite;
}

.bolt-container {
    position: relative;
    display: inline-block;
    /* Or flex, depending on your layout */
}

.speech-bubble {
    position: absolute;
    top: 2;
    left: 120%;
    /* Adjust this value to position the speech bubble right of the bolt icon */
    width: 200px;
    /* Adjust the width as needed */
    background-color: rgb(236, 236, 236);

    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 2;


}

/* Keyframes for bolt 'speaking' animation */
@keyframes boltSpeaking {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px);
    }
}

.bolt-active {
    animation: boltSpeaking 0.5s infinite;
}


.note {
    background-color: #f0f2f5;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
}

.note p {
    margin: 0;
    font-style: italic;
    color: #606770;
}

.bolt-container {
    position: relative;
}

.tooltip {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 1;
}

.bolt-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}


.ai-instruction-heading {
    font-size: 20px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    padding: 10px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}
.resume-builder-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.resume-builder-form {
    margin-top: 20px;
}

.resume-upload,
.job-url-input {
    margin-bottom: 15px;
}

.resume-upload label,
.job-url-input label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
}

.resume-upload input,
.job-url-input input {
    background-color: #f8f9fa;
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.resume-builder-submit {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.resume-builder-submit:hover {
    background-color: #0056b3;
}

/* .resume-builder-section {
    background-color: #e8f0fe;
    padding: 20px 0; /* Adjusted for better spacing */
/* } */

.resume-builder-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
    .resume-builder-container {
        padding: 15px;
    }

    .resume-upload label,
    .job-url-input label {
        font-size: 14px;
    }

    .resume-upload input,
    .job-url-input input {
        padding: 6px;
        font-size: 14px;
    }
}